import type { IServerSideGroupSelectionState } from 'ag-grid-community';

import type { dice } from '@org/query';
import { sortByKey } from '@org/utils';

type CostCenterNode = dice.CostCenterNode;

export interface InitialFilterMap extends CostCenterNode {
  children: dice.CostCenterNode[];
  toggle?: boolean;
}

export const createFilterStructure = (
  response:
    | dice.ImportAssetFilteringGroupResponse
    | dice.ImportJournalFilteringGroupResponse
    | dice.ImportLedgerFilteringGroupResponse,
) => {
  const {
    errorType: _errorType,
    xInternalErrorCode: _xInternalErrorCode,
    ...restResponse
  } = response;

  let newData = new Map<string, InitialFilterMap>();
  let toggles = new Map<string, boolean>();

  if (Object.keys(restResponse).length === 0) {
    return newData;
  }

  const entries = Object.entries(restResponse) as [
    keyof dice.ImportAssetFilteringGroupResponse,
    dice.ImportFilterItem[],
  ][];

  entries.forEach(([key, value]) => {
    if (key.includes('Toggle')) {
      toggles = toggles.set(key, value as unknown as boolean);

      return;
    }
    if (['costCenterSetss', 'costCenterSetKeys'].includes(key)) {
      const ccData = value as dice.CostCenterNode[];

      if (ccData) {
        const res = ccData.map(({ nodeKey = '', isGroup, isSelected, id }) => ({
          nodeKey,
          children: [],
          id,
          isGroup,
          isSelected,
        }));

        newData = newData.set(key, {
          nodeKey: key,
          children: res as dice.CostCenterNode[],
          id: key,
          isGroup: true,
          isSelected: 'NONE',
        });
      }
    } else {
      newData = newData.set(key, {
        children: value.map(({ name, isSelected, id }) => ({
          nodeKey: `${id} ${name}`.trim(),
          /* some nodes can be unique only in section, but not in the whole table
           * this will prepend name of the section to the id -> and will be removed during import data call
           *  */
          id: `${key}-${id}`,
          children: [],
          isGroup: false,
          isSelected,
        })) as dice.CostCenterNode[],
        nodeKey: key,
        id: key,
        isGroup: true,
        isSelected: 'NONE',
      });
    }
  });

  for (const [key, value] of newData.entries()) {
    value.toggle = toggles.get(`${key}Toggle`) ?? false;
    value.children = value.children?.map((child) => ({
      ...child,
      toggle: value.toggle,
    }));

    value.children = sortByKey(value.children, 'id');
  }

  return newData;
};

export const getSelectedNodes = (nodes: CostCenterNode[]) =>
  nodes.reduce<IServerSideGroupSelectionState[]>((acc, node) => {
    const { id: nodeId = '', isSelected } = node;

    if (isSelected === 'FULLY') {
      acc.push({
        nodeId,
        selectAllChildren: true,
        toggledNodes: [],
      });
    } else if (isSelected === 'PARTIALLY') {
      acc.push({
        nodeId,
        selectAllChildren: false,
        toggledNodes: [
          {
            nodeId: 'unknown-node', // representing some selected node in the structure
            selectAllChildren: true,
            toggledNodes: [],
          },
        ],
      });
    }

    return acc;
  }, []);

export const getSelectedNodesTreeRoot = () =>
  ({
    nodeId: undefined,
    selectAllChildren: false,
    toggledNodes: [],
  }) as IServerSideGroupSelectionState;

export const setNodesInSelectedStructure = (
  data: IServerSideGroupSelectionState,
  nodeId: string,
  newData: IServerSideGroupSelectionState[],
) => {
  const stack = [data];
  const visitedIds = [];

  while (stack.length > 0) {
    const next = stack.pop();
    visitedIds.push(next?.nodeId);
    const children = next?.toggledNodes ?? [];
    if (next?.nodeId === nodeId) {
      next.toggledNodes = newData;
    }

    if (children.length > 0) {
      for (const child of children) {
        stack.push(child);
      }
    }
  }

  return data;
};
