import type { GetRowIdParams } from 'ag-grid-community';
import type { FC } from 'react';

import type { BaseEditTableProps } from '@org/ui';
import { WithdrawCapitalMasterDetail } from '@org/features';
import { BaseEditTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const getRowId = ({ data }: GetRowIdParams) => data?.longName + data?.shortName;

interface ManageWithdrawCapitalProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const ManageWithdrawCapitalView: FC<ManageWithdrawCapitalProps> = (
  props: ManageWithdrawCapitalProps,
) => {
  const {
    autoGroupColumnDef,
    columnDefs,
    tableRef,
    defaultColDef,
    onGridReady,
    modal,
    onCellValueChange,
    detailCellRendererParams,
  } = props;

  return (
    <div className="flex h-full flex-col">
      {modal}
      <BaseEditTable
        animateRows
        autoGroupColumnDef={autoGroupColumnDef}
        // @ts-expect-error TODO: fix type
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        detailCellRenderer={WithdrawCapitalMasterDetail}
        detailCellRendererParams={detailCellRendererParams}
        detailRowHeight={525}
        getRowId={getRowId}
        masterDetail
        onCellValueChanged={onCellValueChange}
        onGridReady={onGridReady}
        ref={tableRef}
        singleClickEdit
      />
    </div>
  );
};

export const ManageWithdrawCapitalPage = bindControllers(ManageWithdrawCapitalView, [
  useController,
]);

ManageWithdrawCapitalPage.displayName = 'ManageWithdrawCapitalPage';
