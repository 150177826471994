import type { BaseEditTableProps } from '@org/ui';
import { BaseTable, Button } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { getRowClasses } from '../ReportTable/helpers';
import { useController } from './useController';

interface PriceSheetReportTableProps
  extends Omit<BaseEditTableProps, keyof ControllerType>,
    ControllerType {}

const View = ({
  columnsDefs,
  onGridReady,
  defaultColDef,
  handleExport,
  gridRef,
  defaultExcelExportParams,
  getRowId,
  rowData,
}: PriceSheetReportTableProps) => (
  <>
    <div className="float-right inline-flex items-center gap-4">
      <Button
        onClick={handleExport}
        size="sm"
        variant="primary"
      >
        <span className="appkiticon icon-download-outline" />
        EXPORT
      </Button>
    </div>
    <div className="flex h-[calc(100vh-180px)] w-full flex-auto items-start justify-center overflow-auto p-0 pt-3">
      <BaseTable
        columnDefs={columnsDefs}
        defaultColDef={defaultColDef}
        defaultExcelExportParams={defaultExcelExportParams}
        getRowClass={getRowClasses()}
        getRowId={getRowId}
        groupDefaultExpanded={2}
        groupDisplayType="custom"
        onGridReady={onGridReady}
        ref={gridRef}
        rowData={rowData}
        suppressAggFuncInHeader
      />
    </div>
  </>
);

export const PriceSheetReportTable = bindControllers(View, [useController]);

PriceSheetReportTable.displayName = 'PriceSheetReportTable';
