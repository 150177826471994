import type { ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useMemo, useRef } from 'react';

import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

const defaultColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
  resizable: true,
  sortable: false,
} satisfies ColDef;

export const useController = (apiParams: UseControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);

  const { getCostCenterLongName } = aggregated.useCostCenters(apiParams);

  const { allocationKeyConfig } = aggregated.useCostUnitAllocation({
    ...apiParams,
  });

  const rowData = useMemo(
    () =>
      allocationKeyConfig?.toSorted((itemA, itemB) =>
        itemA?.rankOfCC && itemB?.rankOfCC ? itemA.rankOfCC - itemB.rankOfCC : -1,
      ),
    [allocationKeyConfig],
  );

  const { onGridReady } = useAgGridData({ agGridRef: tableRef, data: rowData });

  const columnDefs = useColumns(getCostCenterLongName);

  const detailCellRendererParams = useMemo(
    () => ({
      apiParams,
    }),
    [apiParams],
  );

  return {
    columnDefs,
    defaultColDef,
    detailCellRendererParams,
    onGridReady,
    tableRef,
  };
};

export type ControllerType = ReturnType<typeof useController>;
