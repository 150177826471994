import type { FC } from 'react';

import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

const View: FC<ControllerType> = (props) => {
  const {} = props;

  return <div className="h-full">Master detail</div>;
};

export const WithdrawCapitalMasterDetail = bindControllers(View, [useController]);

WithdrawCapitalMasterDetail.displayName = 'WithdrawCapitalMasterDetail';
