import type { ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import type { OverridableComponent, OverridableTypeMap } from '../types';

export function createComponent<M extends OverridableTypeMap>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: ForwardRefRenderFunction<Element, any>,
) {
  return forwardRef(render) as unknown as OverridableComponent<M>;
}
