import type { CellValueChangedEvent, ColDef } from 'ag-grid-community';
import type { AgGridReact } from 'ag-grid-react';
import { useCallback, useMemo, useRef } from 'react';

import type { ButtonCellRendererProps } from '@org/ui';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';
import { useDeleteRowConfirmModal } from '@org/ui';

import type { BaseControllerType } from '..';
import { useColumns } from './useColumns';

export interface UseControllerProps extends BaseControllerType {}

const defaultColDef = {
  filter: 'agTextColumnFilter',
  flex: 1,
  floatingFilter: true,
  resizable: true,
  sortable: false,
} satisfies ColDef;

export const useController = (apiParams: UseControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);
  const { masterConfiguration, updateMasterConfiguration } =
    aggregated.useMasterConfiguration(apiParams);

  const { onGridReady } = useAgGridData({
    agGridRef: tableRef,
    data: masterConfiguration?.withdrawCapitalConfig ?? [],
  });

  const addNewRow = useCallback(({ api }: ButtonCellRendererProps) => {
    api?.applyTransaction({ add: [{}] });
  }, []);

  const onCellValueChange = useCallback(
    async ({ colDef, data }: CellValueChangedEvent) => {
      const colName = colDef.field;

      if (!colName) {
        return;
      }

      const configWithoutChanged =
        masterConfiguration?.withdrawCapitalConfig.filter(
          (config) => config.shortName !== data.shortName,
        ) ?? [];

      const withdrawCapitalConfig = [...configWithoutChanged, data];

      await updateMasterConfiguration({
        ...masterConfiguration,
        withdrawCapitalConfig,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const deleteRow = useCallback(
    async ({ node, data, api }: ButtonCellRendererProps) => {
      const configAfterDeletion = masterConfiguration?.withdrawCapitalConfig.filter(
        (config) => config.shortName !== data.shortName,
      );

      api.applyTransaction({
        remove: [node.data],
      });

      await updateMasterConfiguration({
        ...masterConfiguration,
        withdrawCapitalConfig: configAfterDeletion,
      });
    },
    [masterConfiguration, updateMasterConfiguration],
  );

  const handleDelete = useCallback(
    async (btnProps: ButtonCellRendererProps) => {
      await deleteRow(btnProps);
    },
    [deleteRow],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonCellRendererProps>(
    {
      onConfirm: handleDelete,
    },
  );

  const columnDefs = useColumns({ handleDeleteModal, addNewRow });

  const detailCellRendererParams = useMemo(
    () => ({
      apiParams,
    }),
    [apiParams],
  );

  return {
    columnDefs,
    detailCellRendererParams,
    defaultColDef,
    onGridReady,
    tableRef,
    modal,
    onCellValueChange,
  };
};

export type ControllerType = ReturnType<typeof useController>;
