import type { GridApi } from 'ag-grid-community';

import type { APIParams, dice } from '@org/query';

export interface ControllerProps {
  data: dice.AllocationKeyConfigDTO;
  apiParams: APIParams<'masterConfigurationId'>;
  api: GridApi;
}

export const REMAINDER_ALLOC_NAME = 'Remainder config';

export const useController = () => ({});

export type ControllerType = ReturnType<typeof useController>;
